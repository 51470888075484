// import { useEffect, useRef } from "react";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// const DatePickerComponent = ({
//   className,
//   date,
//   handleDateChange,
//   isCalenderOpen,
//   openCalender,
//   closeCalender,
//   disabled,
//   past
// }) => {
//   const inputRef = useRef(null);

//   const handleRefClick = () => {
//     openCalender();
//   };

//   useEffect(() => {
//     if (inputRef.current) {
//       const inputElement = inputRef.current;
//       inputElement.addEventListener("click", handleRefClick);

//       return () => {
//         inputElement.removeEventListener("click", handleRefClick);
//       };
//     }
//   }, [openCalender]);

//   const handleCloseDatePicker = () => {
//     closeCalender();
//   };
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs} id="parent">
//       <DemoContainer id="check" components={["DatePicker"]}>
//         <DemoItem>
//           <div id="parent">
//             <DatePicker
//               open={isCalenderOpen}
//               onOpen={openCalender}
//               onClose={handleCloseDatePicker}
//               className={className}
//               value={date}
//               onChange={handleDateChange}
//               inputRef={inputRef}
//               view={["year", "month", "day"]}
//               onAccept={closeCalender}
//               disabled={disabled}
//               disablePast={past}
//               format="DD/MM/YYYY"
//             />
//           </div>
//         </DemoItem>
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// };

// export default DatePickerComponent;

import { useEffect, useRef } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DatePickerComponent = ({
  className,
  date,
  handleDateChange,
  isCalenderOpen,
  openCalender,
  closeCalender,
  disabled,
  past
}) => {
  const inputRef = useRef(null);

  const handleRefClick = () => {
    openCalender();
  };

  useEffect(() => {
    if (inputRef.current) {
      const inputElement = inputRef.current;
      inputElement.addEventListener("click", handleRefClick);

      return () => {
        inputElement.removeEventListener("click", handleRefClick);
      };
    }
  }, [openCalender]);

  const handleCloseDatePicker = () => {
    closeCalender();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{
          "& .MuiOutlinedInput-root": {
            height: "38px",
          }
        }}
        open={isCalenderOpen}
        onOpen={openCalender}
        onClose={handleCloseDatePicker}
        className={`${className} w-100 form-control`} // Apply width 100% styling
        value={date}
        onChange={handleDateChange}
        inputRef={inputRef}
        views={["year", "month", "day"]}
        onAccept={closeCalender}
        disabled={disabled}
        disablePast={past}
        format="DD/MM/YYYY"
      />
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
