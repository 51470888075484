import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import "../style.css";
import { Link, NavLink } from "react-router-dom";
import TermsAndConditionReplicaModal from "../components/Common/modals/ReplicaTerms";
import PolicyReplica from "../components/Common/modals/ReplicaPolicy";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';

function ReplicaLayout({ children }) {
  const [fillPercentage, setFillPercentage] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const terms = useSelector((state) => state?.replica?.termsAndPolicy?.terms);
  const policy = useSelector((state) => state?.replica?.termsAndPolicy?.policy);
  const companyDetails = useSelector((state) => state.replica?.companyDetails);
  const registerLink = useSelector((state) => state?.replica?.registerLink);
  const favicon = document.getElementById('dynamic-favicon');
  if (favicon) {
    favicon.href = companyDetails?.favicon;
  }
  const currentDate = new Date();
  const currentYear = format(currentDate, 'yyyy');

  // ---------------------------------------- API ----------------------------------------------

  if (params.username && params.hashKey) {
    localStorage.setItem("hashKey", params.hashKey);
    localStorage.setItem("referralId", params.username);
  }

  // --------------------------------------- Function -----------------------------------------
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollDown = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollDown < 135) {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }

    const scrollPercentage =
      (scrollTop / (documentHeight - windowHeight)) * 100;

    const newFillPercentage = Math.min(Math.max(scrollPercentage, 0), 100);

    setFillPercentage(newFillPercentage);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNavLink = () => {
    setShowMobileMenu(false);
    const hash = localStorage.getItem("hashKey");
    const referraiId = localStorage.getItem("referralId");
    navigate(`/replica/${referraiId}/${hash}`);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="pageReplica" id="pageReplica">
      {/* ---------------------------------------- Set child components here -------------------------------------- */}

      {children}

      {/* --------------------------------------------------------------------------------------------------------- */}

      <TermsAndConditionReplicaModal
        show={showTerms}
        setShow={setShowTerms}
        data={terms}
      />
      <PolicyReplica show={showPolicy} setShow={setShowPolicy} data={policy} />
    </div>
  );
}

export default ReplicaLayout;
