import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  showPaymentStatus: false,
  chekoutId: "",
  status: "pending",
};

const checkPaymentSlice = createSlice({
  name: 'paymentCheck',
  initialState,
  reducers: {
    setVisibility: (state, action) => {
      state.showPaymentStatus = action.payload;
    },
    setId: (state, action) => {
      state.chekoutId = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setVisibility, setPaymentStatus, setId } = checkPaymentSlice.actions;

export default checkPaymentSlice.reducer;
