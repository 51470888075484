import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QuickBalance from "../../components/Dashboard/DashboardTiles";
import JoiningChart from "../../components/Dashboard/JoiningChart";
import TeamMembers from "../../components/Dashboard/TeamMembers";
import TeamMembersEarningSection from "../../components/Dashboard/TeamPerformance";
import EarningsExpenses from "../../components/Dashboard/Earnings";
import { ApiHook } from "../../hooks/apiHook";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RankComponent from "../../components/Dashboard/RankComponent";
// import Tour from 'reactour'
import { disableIsTourOpen } from "../../store/reducers/userReducer";
import CheckPayment from "../../components/Dashboard/paymentStatus";
import {
  setId,
  setPaymentStatus,
  setVisibility,
} from "../../store/reducers/checkPaymentReducer";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const joiningChartData = useSelector(
    (state) => state.dashboard?.dashboardOne
  );
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [checkoutId, setChecheckoutId] = useState(null);

  //id returned form hyperpay
  const id = searchParams.get("id");
  const resourcePath = searchParams.get("resourcePath");
  const brand = searchParams.get("brand");

  const navigate = useNavigate();

  // const isTourOpen = useSelector(
  //   (state) => state.user?.isTourOpen
  // );
  // const [isMobileView, setIsMobileView] = useState(false);
  // const [isTourClosed, setIsTourClosed] = useState(false);
  // const accentColor = "#954cea";
  // const endTutorialMutation = ApiHook.CallEndTutorial(setIsTourClosed);

  // const closeTour = () => {
  //   dispatch(disableIsTourOpen());
  //   if (!isTourClosed) {
  //     endTutorialMutation.mutateAsync();
  //   }
  // };
  // useEffect(() => {
  //   // Function to check if mobile view
  //   const checkMobileView = () => {
  //     setIsMobileView(window.innerWidth <= 768); // Assuming 768px as mobile breakpoint
  //   };

  //   // Call the function on initial load
  //   checkMobileView();

  //   // Add event listener to update view on window resize
  //   window.addEventListener("resize", checkMobileView);

  //   // Cleanup the event listener on component unmount
  //   return () => window.removeEventListener("resize", checkMobileView);
  // }, []);

  // const steps = [
  //   {
  //     selector: '[data-tut= "dashboard"]',
  //     content: () => (
  //       <>
  //         <p>Welcome to Infinite MLM User Dashboard, click next to show the tutorial</p>
  //         <a style={{ color: accentColor, cursor: "pointer" }} onClick={closeTour}>skip</a>
  //       </>
  //     )
  //   },
  //   {
  //     selector: isMobileView ? '[data-tut="mobile-navbar"]' : '[data-tut="side-navbar"]',
  //     content: 'These are various quick menus, hover mouse around it to see where it goes.'
  //   },
  //   ...(!isMobileView
  //     ? [
  //       {
  //         selector: '[data-tut="expand-side-menu"]',
  //         content: 'Click on the icon to expand the menu',
  //       }
  //     ] : []),

  //   {
  //     selector: '[data-tut="profile"]',
  //     content: 'Click on the dropdown to see user Profile'
  //   },
  //   {
  //     selector: '[data-tut= "show-tutorial"]',
  //     content: () => (
  //       <>
  //         <p>You can get the tutorial anytime by clicking on the "i" icon</p>
  //         <a style={{ color: accentColor, cursor: "pointer" }} onClick={closeTour}>close</a>
  //       </>
  //     )
  //   }
  // ]

  // --------------------------------------------- API -------------------------------------------------
  const dashboard = ApiHook.CallDashboardTiles();
  const dashboardDetails = ApiHook.CallDashboardDetails();
  const repurchaseMutation = ApiHook.CallPlaceRepurchaseOrder();

  useEffect(() => {
    setChecheckoutId(id);
  }, [id]);

  useEffect(() => {
    if (checkoutId) {
      dispatch(setVisibility(true));
      navigate(location.pathname);
      repurchaseMutation.mutate(
        {
          product: [],
          paymentType: "hyperpay",
          totalAmount: 0,
          hyperpay: { checkoutId, resourcePath, brand: brand.slice(0, -1) },
        },
        {
          onSuccess: (res) => {
            if (res?.data.status) {
              dispatch(setPaymentStatus("success"));
              dispatch(setId(checkoutId));
            } else {
              dispatch(setPaymentStatus("failed"));
            }
            let statusTimeOut = setTimeout(() => {
              dispatch(setVisibility(false));
            }, 5000);
          },
        }
      );
    }
  }, [checkoutId]);

  return (
    <>
      <div data-tut="dashboard" className="page_head_top">
        {t("dashboard")}
      </div>
      <div className="center_content_head">
        <span>
          {t("welcome")} {user?.fullName}{" "}
        </span>
      </div>
      <QuickBalance
        tiles={dashboard?.data}
        currency={userSelectedCurrency}
        conversionFactor={conversionFactor}
      />
      <div className="joining_Teammbr_section">
        <div className="row">
          <JoiningChart charts={joiningChartData} />
          <TeamMembers members={dashboardDetails?.data?.newMembers} />
        </div>
      </div>
      <div className="team_members_earning_section">
        <div className="row">
          <TeamMembersEarningSection
            topEarners={dashboardDetails?.data?.topEarners}
            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
          />
          {!!moduleStatus?.rank_status && (
            <RankComponent
              ranks={dashboardDetails?.data?.ranks}
              currentRank={dashboardDetails?.data?.currentRank}
            />
          )}
          <EarningsExpenses
            earnings={dashboardDetails?.data?.earnings}
            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
          />
        </div>
      </div>
      {/* <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        rounded={5}
        className="helper"
        accentColor={accentColor}
      /> */}
    </>
  );
};

export default Dashboard;
