import React, { useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import DatePickerComponent from "../Common/DatePickerComponent";
import dayjs from "dayjs";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { PhoneInput } from "react-international-phone";
import { useForm } from "react-hook-form";

const AddLeadForm = () => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const {
    register,
    setValue,
    setError,
    trigger,
    watch,
    formState: { errors },
  } = useForm();
  const formValues = watch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setSelectedCountry] = useState("");
  const [interestStatus, setInterestStatus] = useState("");
  const [followupDate, setFollowupDate] = useState(dayjs());
  const [leadStatus, setLeadStatus] = useState();
  const initialFormData = {
    firstName: "",
    lastName: "",
    emailId: "",
    skypeId: "",
    mobileNo: "",
    countryId: "",
    interestStatus: "",
    followupDate: "",
    leadStatus: "",
    description: "",
  };

  const [errorMessage, setErrorMessage] = useState({
    firstName: null,
    lastName: null,
    emailId: null,
    skypeId: null,
    mobileNo: null,
    countryId: null,
    description: null,
    interestStatus: null,
    followupDate: null,
    leadStatus: null,
    description: null,
  });
  const [formData, setFormData] = useState(initialFormData);
  const [isCalenderOpen, setIsCalenderOpen] = useState({
    nextDate: false,
  });

  const interestOptions = [
    {
      label: t("not_interested"),
      value: 0,
    },
    {
      label: t("interested"),
      value: 1,
    },
    {
      label: t("very_interested"),
      value: 2,
    },
  ];

  const statusOptions = [
    {
      label: t("ongoing"),
      value: 1,
    },
    {
      label: t("accepted"),
      value: 2,
    },
    {
      label: t("rejected"),
      value: 0,
    },
  ];

  //------------------------------------------- API --------------------------------------------------

  const addLeadMutation = ApiHook.CallAddCrmLead();
  const countries = ApiHook.CallGetCountries();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrorMessage((prevData) => ({
      ...prevData,
      [name]: null,
    }));
    const requiredIds = ["firstName", "emailId", "description"];
    if (requiredIds.includes(name) && (value === null || value === "")) {
      setErrorMessage((prev) => ({
        ...prev,
        [name]: "*Required",
      }));
    }
    if (name === "emailId") {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(value)) {
        setErrorMessage((prevData) => ({
          ...prevData,
          emailId: "Invalid email format",
        }));
      }
    }
  };
  // validation
  const isPhoneValid = (phone) => {
    return phone.length >= 7 && !isNaN(phone);
  };
  const isPhoneNumberValid = isPhoneValid(phoneNumber);

  const handlePhoneNumber = (phone) => {
    setPhoneNumber(phone);
    setValue("mobileNo", phone);
    setError("mobileNo", { message: "" });
  };

  const countryChange = (country) => {
    setSelectedCountry(country);
    setValue("countryId", country?.value);
    setError("countryId", { message: "" });
  };

  const changeInterestLevel = (level) => {
    setInterestStatus(level);
    setValue("interestStatus", level?.value);
    setError("interestStatus", { message: "" });
  };

  const handleDateChange = (newDate) => {
    if (newDate) {
      setFollowupDate(newDate);
      const formattedDate = newDate.format("YYYY-MM-DD");
      setValue("followupDate", formattedDate);
      setError("followupDate", { message: "" });
    }
  };
  const changeLeadStatus = (status) => {
    setLeadStatus(status);
    setValue("leadStatus", status?.value);
    setError("leadStatus", { message: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = await trigger();
    if ( !isPhoneNumberValid ) {
      isValid = false;
      setError("mobileNo", {
        message: t("min_length"),
      });
    }
    const hasErrors =
      errorMessage.firstName ||
      errorMessage.emailId ||
      errorMessage.description;

    setFormData({
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailId: formData.emailId,
      skypeId: formData.skypeId,
      mobileNo: formData.mobileNo,
      countryId: formData.countryId,
      interestStatus: formData.interestStatus,
      followupDate: formData.followupDate,
      leadStatus: formData.leadStatus,
      description: formData.description,
    });
    if (isValid) {
      addLeadMutation.mutate(formValues, {
        onSuccess: (res) => {
          if (res.status) {
            toast.success(res.data);
            setFormData(initialFormData);
            setSelectedDate("");
          } else {
            toast.error(res?.message);
          }
        },
      });
    }
  };
  
  return (
    <>
      <div className="page_head_top">{t("add_lead")}</div>
      <div className="frm-content">
        <div className="p-3 container-fluid">
          <div className="crm_panel__h6izZ">
            <div className="crm_panel_body__31wq1">
              <form className="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-4">
                    <div className="form-group">
                      <label className="form-label">{t("firstName")}</label>
                      <input
                        {...register("firstName", {
                          required: {
                            value: true,
                            message: t("this_field_is_required"),
                          },
                        })}
                        id="firstName"
                        name="firstName"
                        type="text"
                        className="form-control"
                        onBlur={async () => await trigger("firstName")}
                      />
                      {errors.firstName && (
                        <span className="error-message-validator">
                          {errors.firstName.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t("lastName")}</label>
                      <input
                        {...register("lastName")}
                        name="lastName"
                        type="text"
                        className="form-control"
                        onBlur={async () => await trigger("lastName")}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t("email")}</label>
                      <input
                        {...register("emailId", {
                          required: {
                            value: true,
                            message: t("this_field_is_required"),
                          },
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                            message: t("invalid_email_format"),
                          },
                        })}
                        name="emailId"
                        type="text"
                        className="form-control"
                        onBlur={async () => await trigger("emailId")}
                      />
                      {errors.emailId && (
                        <span className="error-message-validator">
                          {errors.emailId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="form-group">
                      <label className="form-label">{t("skype")}</label>
                      <input
                        {...register("skypeId")}
                        name="skypeId"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t("mobile")}</label>
                      <PhoneInput
                        defaultCountry="us"
                        value={phoneNumber}
                        onChange={handlePhoneNumber}
                      />
                       {errors.mobileNo && (
                        <span className="error-message-validator">
                          {errors.mobileNo.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="countryId" className="form-label">
                        {t("country")}
                      </label>
                      <Select
                        id={"countryId"}
                        name={"countryId"}
                        className={`dropdown-common ${
                          errors["countryId"] ? "error-field" : ""
                        }`}
                        {...register("countryId")}
                        onBlur={async () => await trigger("countryId")}
                        options={countries?.data}
                        onChange={countryChange}
                        value={country}
                        isSearchable
                      />
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="form-group">
                      <label className="form-label">
                        {t("level_of_interest")}
                      </label>
                      <Select
                        id={"interestStatus"}
                        name={"interestStatus"}
                        className={`dropdown-common`}
                        {...register("interestStatus")}
                        options={interestOptions}
                        onChange={changeInterestLevel}
                        value={interestStatus}
                        isSearchable={false}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t("followup_date")}</label>
                      <DatePickerComponent
                        className={"date-picker"}
                        date={followupDate}
                        handleDateChange={(newDate) =>
                          handleDateChange(newDate)
                        }
                        isCalenderOpen={isCalenderOpen.nextDate}
                        openCalender={() =>
                          setIsCalenderOpen({
                            ...isCalenderOpen,
                            nextDate: true,
                          })
                        }
                        closeCalender={() =>
                          setIsCalenderOpen({
                            ...isCalenderOpen,
                            nextDate: false,
                          })
                        }
                        past={true}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t("lead_status")}</label>
                      <Select
                        id={"leadStatus"}
                        name={"leadStatus"}
                        className={`dropdown-common`}
                        options={statusOptions}
                        onChange={changeLeadStatus}
                        value={leadStatus}
                        isSearchable={false}
                      />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="form-group">
                      <label className="form-label">{t("description")}</label>
                      <textarea
                        {...register("description")}
                        name="description"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="Common_customBtn__2_PSp Common_primary__2pdY1 undefined btn btn-primary"
                >
                  {t("add_lead")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLeadForm;
