import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import CardsList from "../../components/Cards/CardsList";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";

const MainCardList = () => {
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //------------------------------------------ API ----------------------------------------

    const data = ApiHook.CallgetCards(currentPage ,itemsPerPage);

    return (
        <>
            <div className="page_head_top">{t("cards")}</div>
            <CardsList
                data={data?.data}
                type={"card-list"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage} />
        </>
    );
};

export default MainCardList;
