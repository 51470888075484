import { Modal } from "react-bootstrap";

const RegisterModal = ({ title, show, handleClose, data }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Use dangerouslySetInnerHTML to render the HTML content */}
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </Modal.Body>
    </Modal>
  );
};

export default RegisterModal;
