// const BASE_URL = "http://192.168.10.40:5011/api";
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const PAYPAL_CLIENT_ID =
//     "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";


//DEMO
// const BASE_URL = "http://162.19.146.135:1438/api"
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

// LIVE
const BASE_URL = "https://admin.mobadala.net/api"
const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID };
