import React, { Fragment, useState } from "react";
import Logo from "../../assests/images/logo_user.png";
import Loader from "../../assests/images/loader.gif"; // Loader image
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { getHyperPayCheckout } from "../../store/actions/shopping";

const paymentMethods = [
  {
    id: 1,
    title: "Madaa",
    value: "MADA",
    icon: "fa-credit-mada",
  },
  {
    id: 2,
    title: "Mastercard",
    value: "MASTER",
    icon: "fa-cc-mastercard",
  },
  {
    id: 3,
    title: "Visa",
    value: "VISA",
    icon: "fa-cc-visa",
  },
  {
    id: 3,
    title: "STC PAY",
    value: "STC_PAY",
    icon: "fa-cc-stc-pay",
  },
];

const CheckoutPayment = ({ register, formValues, errors, totalAmount }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false); // New loading state
  const [activeTab, setActiveTab] = useState("");
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const userData = useSelector((state) => state.user?.loginResponse?.user);
  const clearElement = (element) => {
    // Using innerHTML to clear the contents
    element.innerHTML = ""; // Clears all child elements
  };

  const hyperPayFormSubmit = async (brand) => {
    setLoading(true);
    // Ensure only one tab is active
    setActiveTab(brand);

    const divToAppendForm = document.getElementById("nav-tabContent");
    if (!divToAppendForm) {
      handleError(new Error("Form container not found."));
      setLoading(false);
      return;
    }

    // Clear the div safely
    clearElement(divToAppendForm);

    try {
      const checkoutId = await fetchCheckoutId({
        brand,
        email: `${JSON.parse(userData)?.username}@gmail.com`,
      });
      if (!checkoutId) return;
      await loadHyperPayScript(checkoutId);
      configureWpwlOptions(brand);
      appendPaymentForm(divToAppendForm, checkoutId, brand);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCheckoutId = async ({ brand, email }) => {
    const checkoutId = await getHyperPayCheckout({ brand, email });
    if (!checkoutId)
      throw new Error("Failed to retrieve checkout information.");
    return checkoutId;
  };

  const loadHyperPayScript = (checkoutId) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = `${checkoutId.hyperPayHost}/v1/paymentWidgets.js?checkoutId=${checkoutId.checkoutId}`;
      script.async = true;
      script.onload = resolve;
      script.onerror = () =>
        reject(new Error("Failed to load the HyperPay script."));
      document.body.appendChild(script);
    });
  };

  const configureWpwlOptions = (brand) => {
    const wpwlOptions = {
      style: "plain",
      showCVVHint: true,
      brandDetection: true,
      brandDetectionType: "binlist",
      brandDetectionPriority: [brand],
    };

    const wpwlOptionsScript = document.createElement("script");
    wpwlOptionsScript.textContent = `var wpwlOptions = ${JSON.stringify(
      wpwlOptions
    )};`;
    document.body.appendChild(wpwlOptionsScript);
  };

  const appendPaymentForm = (container, checkoutId, brand) => {
    const formContainer = document.createElement("div");
    formContainer.id = "hyperpayform-div";

    const form = document.createElement("form");
    form.className = "wpwl-form paymentWidgets";
    form.action = `${checkoutId.user}/dashboard?brand=${brand}/`;
    form.dataset.brands = brand;

    formContainer.appendChild(form);
    container.appendChild(formContainer);
  };

  const handleError = (error) => {
    toast.error(
      error.message || "An unexpected error occurred. Please try again later."
    );
    console.error("Error:", error);
  };

  return (
    <div className='col-md-12 m-auto'>
      <div className='payment_section_tab'>
        <div className='regsiter_step_1_view_left_sec_head'>
          {t("payment_type")}
          <br />
          <strong>
            {t("totalAmount")}: {userSelectedCurrency.symbolLeft} {totalAmount}
          </strong>
        </div>

        <div className='row'>
          <div className='col-md-3 pe-0'>
            <div
              className='nav nav-tabs payment_tab_reg'
              id='nav-tab'
              role='tablist'
            >
              {paymentMethods.map((tab) => (
                <Fragment key={tab.id}>
                  <button
                    className={`nav-link custom-tab-button ${activeTab === tab.value ? "active" : ""
                      }`}
                    id={`nav-${tab.id}-tab`}
                    disabled={loading}
                    data-bs-toggle='tab'
                    data-bs-target={`#nav-${tab.id}`}
                    type='button'
                    role='tab'
                    aria-controls={`nav-${tab.id}`}
                    aria-selected={activeTab === tab.value}
                    onClick={() => hyperPayFormSubmit(tab.value)}
                    style={{
                      backgroundColor:
                        activeTab === tab.value ? "#763cb0" : "#f8f9fa",
                      color: activeTab === tab.value ? "#fff" : "#333",
                      border: "1px solid #dee2e6",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      margin: "5px",
                      transition:
                        "background-color 0.3s ease, transform 0.2s ease",
                      transform:
                        activeTab === tab.value ? "scale(1.05)" : "none",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = "#763cb0";
                      e.currentTarget.style.color = "#fff";
                      e.currentTarget.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      if (activeTab !== tab.value) {
                        e.currentTarget.style.backgroundColor = "#f8f9fa";
                        e.currentTarget.style.color = "#333";
                        e.currentTarget.style.transform = "none";
                      }
                    }}
                  >
                    <i className={`fa-brands ${tab.icon} custom-icon`}></i>
                    {t(tab.title)}
                  </button>
                </Fragment>
              ))}
            </div>
          </div>
          <div className='col-md-9 ps-0'>
            {loading && (
              <img src={Loader} alt='Loading...' style={{ width: "30%" }} />
            )}
            <div
              className='tab-content p-3 border mb-5 payment__tab_cnt'
              id='nav-tabContent'
            >
              <div
                className='d-flex flex-column justify-content-center align-items-center'
                style={{ height: "100%" }}
              >
                {loading ? ( // Show loader while loading
                  <img
                    src={Loader}
                    alt='Loading...'
                    style={{ width: "50px", height: "50px" }}
                  />
                ) : activeTab === "" ? (
                  <>
                    {t("enter_type_of_card")}
                    <img src={Logo} alt='' />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {errors.paymentType && (
          <span className='error-message-validator'>*Required</span>
        )}
      </div>
    </div>
  );
};

export default CheckoutPayment;
