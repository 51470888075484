import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';



function CheckPayment() {


  const paymentStatus = useSelector((state) => state.paymentCheck.status);
  const checkouId = useSelector((state) => state.paymentCheck.chekoutId);


  const color = paymentStatus == 'pending' ? 'orange' : paymentStatus == 'success' ? 'green' : 'red'

  return (
    <div style={{ ...compStyle.container, border: '1px solid ' + color, zIndex: '999' }} >
      <div>
        <i className={paymentStatus === "success" ? "fa-solid fa-circle-check fa-3x fa-fw" : "fa fa-exclamation-circle fa-3x fa-fw"} style={{ color: color }}></i>
      </div>

      <div>
      <h5 style={{ color }}>{paymentStatus === "pending" ? `Verifying Transaction`  : `Transaction ${checkouId} ${paymentStatus}`}</h5>
      </div>
    </div>
  )
}


const compStyle = {
  container: { margin: '50px', position: 'fixed', right: '0', bottom: '0', backgroundColor: '#fff', padding: 15, display: 'flex', alignItems: 'center', },
  span: { backgroundColor: '#eee', padding: '2px 5px 2px 5px', margin: 5, borderRadius: 5, },
  buttonClose: { backgroundColor: 'transparent', position: 'absolute', right: 10, top: 10, color: '#aaa' },
  buttonReload: { backgroundColor: '#ffc526', padding: 5, margin: 5 }
}
export default CheckPayment