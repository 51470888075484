import React from "react";
import TableMain from "../Common/table/TableMain";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getLastPage } from "../../utils/getLastPage";

const CardList = (props) => {
    console.log(props, "cards data");
    const { t } = useTranslation();
    const headers = [
        t("slno"),
        t("date"),
        t("cardNumber") 
    ];
    const lastPage = getLastPage(props.itemsPerPage, props?.data?.totalCount );
    return (
        <>
            <div className="table-responsive min-hieght-table">
                <TableMain
                    headers={headers}
                    data={props.data?.data}
                    startPage={1}
                    currentPage={props.currentPage}
                    totalPages={lastPage}
                    type={props.type}
                    itemsPerPage={props.itemsPerPage}
                    setItemsPerPage={props.setItemsPerPage}
                    setCurrentPage={props.setCurrentPage}
                />
            </div>

        </>
    );
};

export default CardList;