import React from "react";
import ProductLists from "../../components/shopping/ProductLists";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";

const ShoppingLayout = () => {
  const { t } = useTranslation();
  const items = ApiHook.CallRepurchaseItems();
  if (items.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Loader
          type='spinner-default'
          bgColor={"#954cea"}
          color={"#954cea"}
          size={50}
        />
      </div>
    );
  }
  if (items && items.data.length == 0) {
    return (
      <></>
      // <div
      //   style={{
      //     display: "flex",
      //     justifyContent: "center",
      //     alignItems: "center",
      //     height: "100vh",
      //     width: "100vw",
      //     position: "fixed",
      //     top: 0,
      //     left: 0,
      //   }}
      // >
      //   <h3>EMPTY</h3>
      // </div>
    );
  }
  return (
    <>
      <div className='page_head_top'>{t("shopping")}</div>
      <div className='ewallet_top_btn_sec'>
        {/* <div className="row justify-content-between">
          <div className="col-md-4 text-end">
            <div className="dropdown btn-group top_right_pop_btn_position">
              <Link to={"/repurchase-report"} className="top_righ_pop_btn">
                {t("repurchase_report")}
              </Link>
            </div>
          </div>
        </div> */}
      </div>

      <ProductLists products={items?.data} />
    </>
  );
};

export default ShoppingLayout;
